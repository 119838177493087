

































import Vue from 'vue'
export default Vue.extend({
  name: 'action-base',
  props: {
    action: {
      type: Object,
      required: true,
    },
    displayDescription: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
})
